import React, { Children } from 'react';

export const CardIconWrapper = (props) => {
  const arrayChildren = Children.toArray(props.children);

  return (
    <div className="absolute top-2.5 right-2.5 p-0.5 !z-20 flex gap-1">
      {Children.map(arrayChildren, (child, i) => (
        <span
          key={i}
          className="flex-center !w-[1.35rem] !h-[1.35rem] rounded-sm shadow-category-icon-shd cursor-pointer btn-edit"
          onClick={props.onClick[i] ? props.onClick[i] : null}>
          {child}
        </span>
      ))}
    </div>
  );
};
