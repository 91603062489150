import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from './../auth';

import { ReactComponent as ClockIcon } from './../../../assets/icons/clock.svg';
import { ReactComponent as CoffeeIcon } from './../../../assets/icons/coffee.svg';
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as ImgIcon } from './../../../assets/icons/img.svg';
import { InnerShadow } from '../../common/Styles';
import { UserContext } from '../../../App';
import { convertToLabel } from '../../common/Utils';

const RecipeDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const pathParts = pathname.split('/');
  const recipeID = pathParts[pathParts.length - 1].split('-')[0];
  const [recipe, setRecipe] = useState();
  const { categories } = useContext(UserContext);

  useEffect(() => {
    axios()
      .get(`/secured/recipes/${recipeID}`)
      .then((resp) => {
        setRecipe(resp.data.Recipe);
      })
      .catch((error) => console.error(error));
  }, [recipeID]);

  return (
    <div className="main-wrapper-margin">
      <section className="relative main-wrapper-style">
        <div
          onClick={() =>
            navigate(
              `/recipes/${recipe.ID}-${convertToLabel(recipe.Title)}/edit`
            )
          }
          className="absolute z-30 ml-auto btn-edit-var top-4 right-3">
          <EditIcon className="four-5th-size" style={{ width: 20 }} />
        </div>
        {recipe && (
          <>
            <h2 className="main-h2-style">{recipe?.Title}</h2>
            <h3 className="flex flex-wrap gap-1 font-title-text">
              <span className="mr-1 text-base-color-2">Categories:</span>
              {recipe.Categories.map((cID, i) => {
                if (i === recipe.Categories.length - 1) {
                  return (
                    <span
                      className="link-text"
                      onClick={() => navigate(`/categories/${cID}`)}>
                      {categories.filter((c) => c.ID === cID)[0].Label}
                    </span>
                  );
                }
                return (
                  <span
                    className="link-text"
                    onClick={() => navigate(`/categories/${cID}`)}>
                    {categories.filter((c) => c.ID === cID)[0].Label},
                  </span>
                );
              })}
            </h3>

            <div className="mt-4 sm:grid sm:grid-cols-2">
              <div className="w-full aspect-[2/1] sm:aspect-square relative overflow-hidden rounded-md">
                {recipe?.Img === '' ? (
                  <div className="full-size card-bg-variations">
                    <div className="w-full h-full pb-2 border-2 rounded-md flex-center border-base-color-2 bg-amber-50 card-color-variations">
                      <ImgIcon className="w-10 h-10" />
                    </div>
                  </div>
                ) : (
                  <>
                    <InnerShadow className="rounded-md" />
                    <img
                      className="object-cover w-full h-full"
                      src={`/img/recipes/${recipe?.Img}`}
                      alt={`recipe_${recipe?.ID}`}
                      // style={{width: 200, height: 200}}
                    />
                  </>
                )}
              </div>

              <ul className="flex flex-col justify-center px-5 pt-4 text-sm sm:pt-0 sm:gap-4 font-title-text">
                <li className="flex gap-1">
                  <ClockIcon className="w-4 h-4 text-base-color-1" />
                  <span className="underline decoration-dotted underline-offset-2 decoration-base-color-1">
                    Prep time:
                  </span>
                  <span>
                    {recipe.PrepTimeList.Hours > 0 &&
                      recipe.PrepTimeList.Hours + 'h'}{' '}
                    {recipe.PrepTimeList.Minutes} min
                  </span>
                </li>

                <li className="flex gap-1">
                  <ClockIcon className="w-4 h-4 text-base-color-1" />
                  <span className="underline decoration-dotted underline-offset-2 decoration-base-color-1">
                    Cook time:
                  </span>{' '}
                  {recipe.CookTimeList.Hours > 0 &&
                    recipe.CookTimeList.Hours + 'h'}{' '}
                  {recipe.CookTimeList.Minutes} min
                </li>

                <li className="flex gap-1">
                  <ClockIcon className="w-4 h-4 text-base-color-1" />
                  <span className="underline decoration-dotted underline-offset-2 decoration-base-color-1">
                    Total time:
                  </span>
                  {recipe.TotalTimeList.Hours > 0 &&
                    recipe.TotalTimeList.Hours + 'h'}{' '}
                  {recipe.TotalTimeList.Minutes} min
                </li>

                <li className="flex gap-1 mt-3">
                  <CoffeeIcon className="w-4 h-4 text-base-color-1" />
                  <span className="underline decoration-dotted underline-offset-2 decoration-base-color-1">
                    Servings:
                  </span>{' '}
                  {recipe.Servings}
                </li>
              </ul>
            </div>

            <p className="mt-6 text-lg font-semibold font-title-text text-base-color-1">
              Ingredients:
            </p>
            <ul className="mx-2">
              {recipe.Ingredients.map((ing) => (
                <li
                  key={ing.ID}
                  className="list-disc list-inside text-base-color-2">
                  <span className="text-text-color">
                    {ing.Amount > 0 && ing.Amount + ' '}
                    {ing.Unit !== '' && ing.Unit + ' '}
                    {ing.Name}
                  </span>
                </li>
              ))}
            </ul>

            <p className="mt-6 text-lg font-semibold font-title-text text-base-color-1">
              Directions:
            </p>
            <ul className="pl-4">
              {recipe.DirectionList?.map((dir, i) => (
                <Fragment key={i}>
                  {recipe.DirectionList.length > 1 && (
                    <li className="mt-3 font-semibold font-title-text text-base-color-2">
                      Step {i + 1}
                    </li>
                  )}
                  <li className="pl-2">{dir}</li>
                </Fragment>
              ))}
            </ul>
          </>
        )}
      </section>
    </div>
  );
};

export default RecipeDetails;
