import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ListIcon } from '../../assets/icons/list_px.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { logout } from './auth';
import { UserContext } from '../../App';
import DropDownMenu from './DropDownMenu';
import Searchbar from './search/Searchbar';

const Header = () => {
  const navigate = useNavigate();
  const [dropDown, setDropDown] = useState('');
  const listRef = useRef();
  const addRef = useRef();
  const userRef = useRef();
  const { currentUser, removeCurrentUser } = useContext(UserContext);

  const onLogout = () => {
    removeCurrentUser();
    logout();
    navigate('/login');
  };

  const dropDownItems = {
    list: [
      { title: '+ New Recipe', link: () => navigate('/recipes/new') },
      { title: '+ New Category', link: () => navigate('/categories/edit') },
      { title: 'Sign out', link: onLogout },
    ],
    add: [],
    user: [
      // { title: 'Settings', link: () => {} },
      { title: 'Sign out', link: onLogout },
    ],
  };

  const toggleDropDown = (type) => {
    setDropDown((prev) => (prev === type ? '' : type));
  };

  const handleClickOutside = (target) => {
    let currentDropDown;
    switch (dropDown) {
      case 'list':
        currentDropDown = listRef?.current;
        break;
      case 'add':
        currentDropDown = addRef?.current;
        break;
      case 'user':
        currentDropDown = userRef?.current;
        break;
      default:
        break;
    }
    if (target !== currentDropDown) {
      setDropDown('');
    }
  };

  return (
    <>
      <header className="fixed top-0 left-0 z-40 flex items-center justify-between w-full gap-3 px-3 h-navbar-height min-h-[4rem] bg-app-white shadow-navbar-shd">
        <div
          className="flex items-center gap-3 cursor-pointer"
          onClick={() => navigate('/categories')}>
          <Logo
            className="!text-base-color-1"
            style={{ width: 45, height: 45 }}
          />

          <h1
            className={`${
              currentUser ? 'hidden' : ''
            } pt-1 text-xl font-semibold tracking-tighter sm:block sm:font-title-text text-base-color-1 whitespace-nowrap`}>
            My Menu Manual
          </h1>
        </div>
        {currentUser && (
          <>
            <Searchbar />

            <div className="flex items-center gap-3">
              {/* <div className="block btn-icon md:hidden">
                <SearchIcon className="navbar-svg" style={{ width: 20 }} />
              </div> */}

              {/* <div
                className={`btn-icon !hidden sm:!flex ${
                  dropDown === 'add' ? '!icon-bg-hover' : null
                }`}>
                <PlusIcon
                  className="navbar-svg"
                  style={{ width: 20 }}
                  ref={addRef}
                  onClick={() => toggleDropDown('add')}
                />
              </div> */}

              {/* <div className="!hidden btn-icon">
                <CartIcon className="navbar-svg" style={{ width: 20 }} />
              </div>

              <div
                className={`btn-icon !hidden sm:!flex  ${
                  dropDown === 'user' ? '!icon-bg-hover' : null
                }`}>
                <UserIcon
                  className="navbar-svg"
                  ref={userRef}
                  style={{ width: 20 }}
                  onClick={() => toggleDropDown('user')}
                />
              </div> */}

              <div
                className={`btn-icon ${
                  dropDown === 'list' ? '!icon-bg-hover' : null
                }`}>
                <ListIcon
                  className="navbar-svg"
                  ref={listRef}
                  style={{ width: 20 }}
                  onClick={() => toggleDropDown('list')}
                />
              </div>
            </div>
          </>
        )}
      </header>
      {dropDown !== '' && (
        <DropDownMenu
          items={dropDownItems[dropDown]}
          dropDownType={dropDown}
          onClick={() => setDropDown('')}
          onClickOutside={handleClickOutside}
          navigate={navigate}
        />
      )}
    </>
  );
};

export default Header;
