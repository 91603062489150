import { useContext, useRef } from 'react';
import { UserContext } from '../../App';
import useOutsideAlerter from '../common/OutsidClickDetecter';
import { Capitalize } from '../common/Utils';

const DropDownMenu = ({ items, dropDownType, onClick, onClickOutside }) => {
  const { currentUser } = useContext(UserContext);

  const clickDetecterRef = useRef();
  useOutsideAlerter(clickDetecterRef, onClickOutside);

  return (
    <div
      ref={clickDetecterRef}
      className="fixed top-0 z-40 shadow-footer-shd p-4 rounded-br-3xl m-navbar-height rounded-bl-sm rounded-tl-3xl min-w-[10rem] w-fit bg-app-white border-b-2 border-l border-base-color-2 right-1">
      <p className="mb-2 font-semibold tracking-widest text-center border-b-2 rounded-xl border-base-color-2 font-title-text text-base-color-2">
        {currentUser.username}
      </p>
      <ul>
        {items.map((item, idx) => (
          <li
            key={idx}
            onClick={() => {
              item.link();
              onClick();
            }}
            className="tracking-wide cursor-pointer hover:text-base-color-1 border-y border-app-white border-spacing-4 hover:border-base-color-2 hover:drop-shadow-sm">
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropDownMenu;
