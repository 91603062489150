import axios from 'axios';
import jwt_decode from 'jwt-decode';

export const authenticateUser = () => {
  const jwtToken = localStorage.getItem('token');
  try {
    const decodedToken = jwt_decode(jwtToken);
    const expireTime = Math.floor(
      (Date.now() - 7 * 24 * 60 * 60 * 1000) / 1000
    );

    if (decodedToken.exp > expireTime) {
      return decodedToken;
    } else {
      logout();
      return null;
    }
  } catch (error) {
    logout();
    return null;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

const authAxios = () => {
  const jwt = localStorage.getItem('token');
  const authAxios = axios.create({
    timeout: 5000,
    headers: { Authorization: `Bearer ${jwt}` },
  });
  return authAxios;
};

export default authAxios;
