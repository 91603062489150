import React, { Fragment, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ReactComponent as TrashIcon } from './../../../../assets/icons/trash.svg';

const IngredientsForm = ({
  ingredients,
  inputStyles,
  onSaveIng,
  onSetErr,
  onClearErr,
  onDeleteIng,
  emptyNames,
}) => {
  const {
    control,
    register,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      Ingredients: [{ amount: '', unit: '', name: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Ingredients',
  });
  const AMOUNT = 'amount';
  const UNIT = 'unit';
  const NAME = 'name';

  const deleteIng = (i) => {
    remove(i);
    onDeleteIng(i);
  };

  useEffect(() => {
    const newIngredients = [];

    if (ingredients) {
      for (let i = 0; i < ingredients.length; i++) {
        const ing = {
          amount: ingredients[i].Amount,
          unit: ingredients[i].Unit,
          name: ingredients[i].Name,
        };
        newIngredients.push(ing);
        onSaveIng(ing, i);
      }
      reset({ Ingredients: newIngredients });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredients, reset]);

  useEffect(() => {
    for (const val of emptyNames) {
      setError(`Ingredients.${val}.name`, {
        type: 'required',
        message: 'Enter an ingredient name.',
      });
      onSetErr(NAME, val);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyNames]);

  const handleSaveIng = (field, val, idx) => {
    const ingVals = getValues('Ingredients')[idx];

    let newVals;
    switch (field) {
      case AMOUNT:
        clearErrors(`Ingredients.${idx}.amount`);
        onClearErr(AMOUNT, idx);

        ConvertAmountToNum(val, () => {
          setError(`Ingredients.${idx}.${AMOUNT}`, {
            type: 'NaN',
            message: 'Amount should be a number.',
          });
          onSetErr(AMOUNT, idx);
        });

        newVals = { ...ingVals, amount: val };
        break;
      case UNIT:
        newVals = { ...ingVals, unit: val };
        break;
      case NAME:
        clearErrors(`Ingredients.${idx}.name`);
        onClearErr(NAME, idx);

        if (val === '') {
          setError(`Ingredients.${idx}.name`, {
            type: 'required',
            message: 'Enter an ingredient name.',
          });
          onSetErr(NAME, idx);
          return;
        }

        newVals = { ...ingVals, name: val };
        break;
      default:
        break;
    }
    onSaveIng(newVals, idx);
  };

  return (
    <div className="mb-4">
      {fields.map((f, i) => (
        <Fragment key={f.id}>
          <div className={`${inputStyles.wrapper.replace('group', '')}`}>
            <div className="flex flex-row sm:flex-col group">
              <label
                htmlFor={AMOUNT + i}
                className={`${inputStyles.label} !text-left`}>
                Amount
              </label>
              <input
                className={`${inputStyles.input} !w-20 ${
                  errors.Ingredients && errors.Ingredients[`${i}`]?.amount
                    ? '!border-base-color-1'
                    : null
                }`}
                id={AMOUNT + i}
                {...register(`Ingredients.${i}.${AMOUNT}`)}
                onChange={(e) => handleSaveIng(AMOUNT, e.target.value, i)}
              />
            </div>

            <div className="flex sm:flex-col group">
              <label
                htmlFor={UNIT + i}
                className={`${inputStyles.label} !text-left`}>
                Unit
              </label>
              <input
                className={`${inputStyles.input} !w-20`}
                id={UNIT + i}
                {...register(`Ingredients.${i}.${UNIT}`)}
                onChange={(e) => handleSaveIng(UNIT, e.target.value, i)}
              />
            </div>

            <div className="flex w-full sm:flex-col group">
              <label
                htmlFor={NAME + i}
                className={`${inputStyles.label} !text-left`}>
                Name
              </label>
              <input
                className={`${inputStyles.input} flex-grow ${
                  errors.Ingredients && errors.Ingredients[`${i}`]?.name
                    ? '!border-base-color-1'
                    : null
                }`}
                id={NAME + i}
                {...register(`Ingredients.${i}.${NAME}`)}
                onChange={(e) => handleSaveIng(NAME, e.target.value, i)}
              />
            </div>
            <button
              type="button"
              className="btn-edit !p-0 !rounded-full flex-shrink-0 self-end mb-0.5"
              onClick={() => deleteIng(i)}>
              <TrashIcon style={{ width: '20px' }} />
            </button>
          </div>
          {errors.Ingredients && (
            <p className="!ml-0 !mt-0 error-msg-sm">
              {errors.Ingredients[`${i}`]?.amount
                ? errors.Ingredients[`${i}`]?.amount.message
                : errors.Ingredients[`${i}`]?.name
                ? errors.Ingredients[`${i}`]?.name.message
                : null}
            </p>
          )}
        </Fragment>
      ))}
      <button
        type="button"
        className="btn-edit px-2 py-1 rounded-sm text-sm !w-fit ml-3 mt-3 "
        onClick={() => {
          append({ amount: '', unit: '', name: '' });
        }}>
        + Add Ingredient
      </button>
    </div>
  );
};

export const ConvertAmountToNum = (val, onError) => {
  let numVal = parseFloat(val);

  if (val && val.toString().includes('/')) {
    const valArr = val.split('/').filter((el) => el !== '');
    if (valArr.length === 2) {
      numVal = valArr[0] / valArr[1];
    }
  }

  if (val === '') {
    numVal = 0;
  }

  if (isNaN(numVal)) {
    onError();
    return;
  }

  return numVal;
};

export default IngredientsForm;
