import React from 'react';

const WhatsNew = () => {
  return (
    <div className="main-wrapper-margin">
      <section className="main-wrapper-style">
        <h2 className="main-h2-style">What's New?</h2>
        <div className="mx-2 my-4">
          <div className="px-3 py-1 border border-gray-200 shadow-inner rounded-3xl">
            <h3 className="flex flex-wrap items-baseline justify-center gap-x-4 sm:justify-between main-h3-style">
              <span>Search for recipes</span>
              <span className="!text-sm text-text-color">
                Thursday, 23 May 2024
              </span>
            </h3>
            <p className="p-1 mt-2">
              A search bar has been added to the page for searching recipes. You
              can now set filters based on the attributes you want to search.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatsNew;
