import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from '../auth';
import { UserContext } from '../../../App';
import { ReactComponent as ForkSvg } from '../../../assets/icons/fork.svg';
import { ReactComponent as KnifeSvg } from '../../../assets/icons/knife.svg';

const Login = () => {
  const { changeCurrentUser } = useContext(UserContext);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const userRef = useRef();
  const passwordRef = useRef();
  const [loginErr, setLoginErr] = useState('');
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
    window.addEventListener('resize', () =>
      setWindowHeight(window.innerHeight)
    );
  }, []);

  const login = () => {
    setLoginErr('');

    const data = {
      User: userRef.current.value,
      Password: passwordRef.current.value,
      RecaptchaToken: recaptchaToken,
    };

    axios()
      .post(`/public/sign_in`, data)
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        changeCurrentUser();
        navigate('/categories');
      })
      .catch((err) => {
        if (err.response?.status === 422) {
          setLoginErr('wrong_user');
        } else if (err.response?.data?.error_type === 'recaptcha_check') {
          setLoginErr('recaptcha_check');
        } else {
          setLoginErr('server_error');
        }
        window.grecaptcha.reset();
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login();
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  return (
    <div
      className={`w-full gap-5 p-2 !pb-0 main-height md:p-10 flex-center ${
        windowHeight < 640 ? '!h-fit' : null
      }`}>
      <div className="hidden w-28 h-96 text-app-white sm:block">
        <ForkSvg />
      </div>
      <div className="max-w-full p-10 pt-6 rounded-full w-96 bg-app-white shadow-category-shd">
        <h2 className="p-5 py-0 mb-6 text-xl text-center border-b-4 border-dotted font-title-text text-base-color-1 border-base-color-2">
          Login
        </h2>
        <form
          className="flex flex-col items-start gap-1"
          onSubmit={onSubmit}
          onKeyDownCapture={onEnter}>
          <label htmlFor="login_name" className="flex items-center">
            <p className="mr-2 text-right">Username or email</p>
          </label>
          <input className="w-full input-style" ref={userRef} id="login_name" />
          <label htmlFor="login_password" className="flex items-center">
            <p className="mr-2 text-right">Password</p>
          </label>
          <input
            type="password"
            className="w-full input-style"
            ref={passwordRef}
            id="login_password"
          />
          {loginErr === 'wrong_user' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              Incorrect username or password.
            </p>
          ) : loginErr === 'recaptcha_check' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              Verify that you are not a robot.
            </p>
          ) : loginErr === 'server_error' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              Oops, something went wrong.
            </p>
          ) : null}
          <p className="w-full text-sm text-center">
            Don't have an account yet?
            <span
              className="ml-1 link-text"
              onClick={() => navigate('/register')}>
              Sign Up
            </span>
          </p>
          <div className="flex w-full flex-center">
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={(value) => setRecaptchaToken(value)}
            />
          </div>
          <div className="w-full mt-2 flex-center">
            <button type="submit" className="w-32 mt-2 btn-base">
              Log in
            </button>
          </div>
        </form>
      </div>
      <div className="hidden w-28 h-96 text-app-white sm:block">
        <KnifeSvg />
      </div>
    </div>
  );
};

export default Login;
