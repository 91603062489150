import React from 'react';

const PrivacyPolicy = () => {
  const h3Styles =
    'font-title-text font-semibold text-base-color-1 indent-2 underline decoration-dotted';
  const textStyles = 'mb-3';
  const listStyles = 'list-disc pl-5';
  return (
    <div className="h-full main-wrapper-margin">
      <section className="main-wrapper-style">
        <div>
          <h2 className="main-h2-style">Privacy Policy</h2>
          <h3 className="mb-3 text-sm">
            Effective Date: Monday, 9 October 2023.
          </h3>
          <h3 className={h3Styles}>1. Introduction</h3>
          <p className={textStyles}>
            Welcome to us. We are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and protect the
            personal information you provide to us.
          </p>
          <h3 className={h3Styles}>2. Information We Collect</h3>
          <div className={textStyles}>
            We may collect the following types of personal information:
            <ul className={listStyles}>
              <li>
                Contact Information: Your name, email address, mailing address,
                phone number, and other similar contact information.
              </li>
              <li>
                Demographic Information: Information about your age, gender, and
                other demographic details.
              </li>
              <li>
                Account Information: If you create an account with us, we may
                collect your username, password, and other account-related
                information.
              </li>
              <li>
                Payment Information: If you make a purchase, we may collect
                payment information, such as credit card details or other
                payment methods. Usage Information: Information about how you
                interact with our website, products, and services, including
                your IP address, browser type, device information, and browsing
                history.
              </li>
              <li>
                Location Information: We may collect information about your
                location, primarily if you use our mobile app or other
                location-based services.
              </li>
              <li>
                Other Information: Any other information you voluntarily provide
                to us.
              </li>
            </ul>
          </div>
          <h3 className={h3Styles}>3. How We Use Your Information</h3>
          <div className={textStyles}>
            We may use your personal information for the following purposes:
            <ul className={listStyles}>
              <li>To provide and improve our products and services.</li>
              <li>
                To communicate with you, respond to your inquiries, and provide
                customer support.
              </li>
              <li>To process your transactions and fulfill orders.</li>
              <li>
                To personalize your experience and deliver relevant content.
              </li>
              <li>
                To send promotional and marketing communications (you can opt
                out at any time).
              </li>
              <li>To monitor and analyze website usage and performance.</li>
              <li>To comply with legal and regulatory requirements.</li>
            </ul>
          </div>
          <h3 className={h3Styles}>4. Information Sharing</h3>
          <div className={textStyles}>
            We may share your personal information with third parties for the
            following purposes:
            <ul className={listStyles}>
              <li>
                Service Providers: We may share your information with
                third-party service providers who assist us in providing our
                services.
              </li>
              <li>
                Legal Compliance: We may disclose your information to comply
                with legal obligations, respond to lawful requests, or protect
                our rights, privacy, safety, or property.
              </li>
              <li>
                Business Transfers: In the event of a merger, acquisition, or
                sale of all or a portion of our assets, your information may be
                transferred to the acquiring entity.
              </li>
              <li>
                With Your Consent: We may share your information with third
                parties when you give us explicit consent.
              </li>
            </ul>
          </div>
          <h3 className={h3Styles}>5. Security</h3>
          <p className={textStyles}>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
            However, no method of transmission over the internet or electronic
            storage is entirely secure.
          </p>
          <h3 className={h3Styles}>6. Your Choices</h3>
          <div className={textStyles}>
            You have the right to:
            <ul className={listStyles}>
              <li>Access and correct your personal information.</li>
              <li>Withdraw your consent.</li>
              <li>Opt-out of marketing communications.</li>
              <li>Request deletion of your account and data.</li>
              <li>Lodge a complaint with the appropriate authorities.</li>
            </ul>
          </div>
          <h3 className={h3Styles}>7. Changes to this Privacy Policy</h3>
          <p className={textStyles}>
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. The updated policy will be posted on our website
            with the revised "Effective Date."
          </p>
          <h3 className={h3Styles}>8. Contact Us</h3>
          <p className={textStyles}>
            If you have questions or concerns about this Privacy Policy or our
            data practices, please contact us at{' '}
            <span className="font-bold text-base-color-2">
              mymenumanual@gmail.com
            </span>
            .
          </p>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
