import React, { useEffect, useState } from 'react';

const RecipeCategories = ({
  recipeCats,
  onAdd,
  onRemove,
  inputStyles,
  allCats,
}) => {
  const [filteredCats, setFilteredCats] = useState(allCats);

  useEffect(() => {
    setFilteredCats(allCats);
  }, [allCats]);

  const onSelectCat = (e) => {
    e.preventDefault();
    const selectedID = e.target.value;
    e.target.value = '';
    const newCats = filteredCats.filter((c) => c.ID !== parseInt(selectedID));
    setFilteredCats(newCats);
    onAdd(selectedID);
  };

  const onRemoveCat = (selectedID) => {
    const removedCat = allCats.filter((c) => {
      return c.ID === parseInt(selectedID);
    })[0];

    setFilteredCats((prev) => [...prev, removedCat]);
    onRemove(selectedID);
  };

  return (
    <div>
      {recipeCats.length > 0 && (
        <div className="flex gap-1 mb-1.5">
          {recipeCats?.map((cat) => (
            <p
              key={cat.ID}
              className="!w-fit pl-2 pr-5 btn-edit !bg-base-color-2 hover:cursor-default relative">
              {cat.Label}
              <span
                className="absolute font-bold cursor-pointer font-title-text -top-1 right-1 text-base-color-1"
                title="remove category"
                onClick={() => onRemoveCat(cat.ID)}>
                x
              </span>
            </p>
          ))}
        </div>
      )}
      <select
        className={`${inputStyles.input} w-full sm:w-auto flex-grow`}
        id="rCategories"
        onChange={onSelectCat}>
        <option selected disabled value="">
          Select a category
        </option>
        {filteredCats?.map((cat) => {
          if (cat.ID === 0) {
            return null;
          }
          return (
            <option key={cat.ID} value={cat.ID}>
              {cat.Label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default RecipeCategories;
