import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import axios from '../../auth';

import {
  Confirm,
  GetIDFromPathName,
  convertToLabel,
} from '../../../common/Utils';
import DirectionsForm from './DirectionsForm';
import IngredientsForm, { ConvertAmountToNum } from './IngredientsForm';
import UploadImage from './UploadImage';
import RecipeCategories from './RecipeCategories';
import { UserContext } from '../../../../App';
import { ReactComponent as TrashIcon } from './../../../../assets/icons/trash.svg';

const inputStyles = {
  wrapper:
    'group flex flex-col sm:flex-row items-start sm:items-center w-full gap-x-3 gap-y-0',
  label:
    'font-title-text text-base-color-2 group-focus-within:text-base-color-3 w-20 sm:text-right mt-1 font-semibold',
  input: 'input-style focus:border-base-color-3 focus:shadow-md',
  timeWrapper: 'flex gap-3 items-center',
};

export const DeleteRecipe = (id, onDelete) => {
  axios()
    .delete(`/secured/recipes/${id}`)
    .then(() => onDelete())
    .catch((error) => console.error(error));
};

const getCatPath = (selectedCats, allCats) => {
  const catID = selectedCats[0]?.ID || 0;
  let catPath;
  if (catID !== 0) {
    const catName = allCats.find((obj) => obj.ID === catID)?.Label;
    catPath = `/categories/${catID}-${convertToLabel(catName)}`;
  } else {
    catPath = '/categories/0';
  }
  return catPath;
};

const RecipeForm = () => {
  const { pathname } = useLocation();
  const [recipe, setRecipe] = useState();
  const [ingredients, setIngredients] = useState([]);
  const [directions, setDirections] = useState([]);
  const [img, setImg] = useState();
  const [deleteImgName, setDeleteImgName] = useState('');
  const [totalTime, setTotalTime] = useState();
  const { categories: allCats } = useContext(UserContext);
  const [selectedCats, setSelectedCats] = useState([]);
  const [ingNameErrors, setIngNameErrors] = useState([]);
  const recipeID = GetIDFromPathName(pathname, 2);
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchFields = useWatch({
    control,
    PrepTime: {
      Hours: '',
      Minutes: '',
    },
    CookTime: {
      Hours: '',
      Minutes: '',
    },
  });

  useEffect(() => {
    axios()
      .get(`/secured/recipes/${recipeID}`)
      .then((resp) => {
        setRecipe(resp.data.Recipe);

        const newIngs = [];
        for (const ing of resp.data.Recipe.Ingredients) {
          newIngs.push({ Amount: ing.Amount, Unit: ing.Unit, Name: ing.Name });
        }
        setIngredients(newIngs);

        setDirections(resp.data.Recipe.DirectionList || []);
      })
      .catch((error) => console.error(error));
  }, [recipeID]);

  useEffect(() => {
    if (recipe) {
      reset({
        Title: recipe.Title,
        PrepTime: {
          Hours: recipe.PrepTimeList.Hours,
          Minutes: recipe.PrepTimeList.Minutes,
        },
        CookTime: {
          Hours: recipe.CookTimeList.Hours,
          Minutes: recipe.CookTimeList.Minutes,
        },
        Servings: recipe.Servings,
      });

      if (allCats) {
        const cats = [];
        for (const cID of recipe.Categories) {
          cats.push(allCats.filter((c) => c.ID === parseInt(cID))[0]);
        }
        setSelectedCats(cats);
      }
    }
  }, [recipe, reset, allCats]);

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (ingredients) {
      const errIndexes = [];

      for (let i = 0; i < ingredients.length; i++) {
        const ing = ingredients[i];

        if (!ing) {
          continue;
        }
        // check if ingredient has empty name
        if (
          !(ing.Amount === 0 && ing.Unit === '' && ing.Name === '') &&
          ing.Name === ''
        ) {
          errIndexes.push(i);
        }

        // convert to float
        const numVal = ConvertAmountToNum(ing.Amount, () => {});
        ingredients[i] = {
          ...ing,
          Amount: Math.round(numVal * 100) / 100,
        };
      }
      if (errIndexes.length > 0) {
        setIngNameErrors(errIndexes);
        return;
      }

      const filteredIngs = ingredients.filter(
        (ing) =>
          ing !== null &&
          !(ing.amount === 0 && ing.unit === '' && ing.name === '')
      );
      data.Ingredients = filteredIngs;
    }

    if (directions) {
      const filteredDirections = directions.filter((dir) => dir !== null);
      data.Directions = filteredDirections;
    }

    const catIDs = [];
    for (const c of selectedCats) {
      catIDs.push(c.ID);
    }
    data.Categories = catIDs;
    data.DeleteImgName = deleteImgName;

    const form = new FormData();
    form.append('data', JSON.stringify(data));
    if (img) {
      form.append('img', img);
    }

    axios()
      .post(`/secured/recipes/${recipeID}/update`, form)
      .then(() => {
        navigate(getCatPath(selectedCats, allCats));
      })
      .catch((error) => console.error(error));
  };

  const addIngredient = (values, i) => {
    let newIngredients = ingredients;

    newIngredients[i] = {
      Amount: values.amount,
      Unit: values.unit,
      Name: values.name,
    };

    setIngredients(newIngredients);
  };

  const deleteIngredient = (i) => {
    let newIngredients = ingredients;

    if (newIngredients.length > 0) {
      newIngredients.splice(i, 1);
    } else {
      newIngredients = [];
    }

    setIngredients(newIngredients);
  };

  const addStep = (value, i) => {
    let newDirections = directions;
    newDirections[i] = value;
    setDirections(newDirections);
  };

  const deleteStep = (i) => {
    let newDirections = directions;

    if (newDirections.length > 0) {
      newDirections.splice(i, 1);
    } else {
      newDirections = [];
    }

    setDirections(newDirections);
  };

  useEffect(() => {
    setTotalTime({
      Hours:
        (getValues('PrepTime.Hours') || 0) + (getValues('CookTime.Hours') || 0),
      Minutes:
        (getValues('PrepTime.Minutes') || 0) +
        (getValues('CookTime.Minutes') || 0),
    });
  }, [watchFields, getValues]);

  const addCat = (catID) => {
    if (allCats) {
      const newCat = allCats.filter((c) => c.ID === parseInt(catID))[0];
      setSelectedCats((prev) => [...prev, newCat]);
    }
  };

  const removeCat = (catID) => {
    const newCats = selectedCats.filter((c) => c.ID !== catID);
    setSelectedCats(newCats);
  };

  const setIngErr = (field, idx) => {
    setError(`ingredient_${field}_${idx}`, {
      type: field,
      message: `Check the "${field}" field of the ${idx + 1}. ingredient`,
    });
  };

  const clearIngErr = (field, idx) => {
    clearErrors(`ingredient_${field}_${idx}`);
  };

  const resetImg = () => {
    setImg(null);
    setDeleteImgName(recipe.Img);
    recipe.Img = '';
  };

  return (
    <div className="main-wrapper-margin">
      <section className="relative sm:pb-5 main-wrapper-style">
        <div
          onClick={() =>
            Confirm(() =>
              DeleteRecipe(
                recipe.ID,
                navigate(getCatPath(selectedCats, allCats))
              )
            )
          }
          className="absolute z-30 ml-auto btn-edit-var top-4 right-3">
          <TrashIcon className="four-5th-size" style={{ width: 20 }} />
        </div>
        <h2 className="main-h2-style">Edit recipe</h2>

        <form
          className="flex flex-col gap-4 mt-6 mb-2"
          onSubmit={handleSubmit(onSubmit)}>
          <label className={inputStyles.wrapper} htmlFor="rTitle">
            <span className={inputStyles.label}>Title</span>
            <input
              className={`${inputStyles.input} w-full sm:w-auto flex-grow ${
                errors.Title ? 'input-error' : null
              }`}
              id="rTitle"
              {...register('Title', {
                required: 'Enter a recipe name.',
              })}
            />
          </label>
          {errors.Title && (
            <p className="error-msg-sm">{errors.Title.message}</p>
          )}
          <label
            className={`${inputStyles.wrapper} ${
              selectedCats.length > 0 ? 'sm:!items-start' : null
            }`}
            htmlFor="rCategores">
            <span className={inputStyles.label}>Categories</span>
            <RecipeCategories
              recipeCats={selectedCats}
              onAdd={addCat}
              onRemove={removeCat}
              inputStyles={inputStyles}
              allCats={allCats}
            />
          </label>
          {errors.Category && (
            <p className="error-msg-sm">{errors.Category.message}</p>
          )}

          <UploadImage
            inputStyles={inputStyles}
            img={img}
            imgName={recipe?.Img}
            onSelect={(data) => setImg(data)}
            onReset={resetImg}
          />

          <div className={inputStyles.wrapper}>
            <span className={inputStyles.label}>Prep Time</span>

            <div className={inputStyles.timeWrapper}>
              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.PrepTime?.Hours ? 'input-error' : null
                }`}
                type="number"
                id="rPrepTimeH"
                {...register('PrepTime.Hours', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rPrepTimeH">
                hours
              </label>

              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.PrepTime?.Minutes ? 'input-error' : null
                }`}
                type="number"
                id="rPrepTimeM"
                {...register('PrepTime.Minutes', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rPrepTimeM">
                minutes
              </label>
            </div>
          </div>
          {errors.PrepTime && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <div className={inputStyles.wrapper}>
            <span className={inputStyles.label}>Cook Time</span>

            <div className={inputStyles.timeWrapper}>
              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.CookTime?.Hours ? 'input-error' : null
                }`}
                type="number"
                id="rCookTimeH"
                {...register('CookTime.Hours', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rCookTimeH">
                hours
              </label>

              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.CookTime?.Minutes ? 'input-error' : null
                }`}
                type="number"
                id="rCookTimeM"
                {...register('CookTime.Minutes', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rCookTimeM">
                minutes
              </label>
            </div>
          </div>
          {errors.CookTime && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <div className={inputStyles.wrapper}>
            <p className={inputStyles.label}>Total Time</p>
            <p>
              {totalTime?.Hours > 0 && totalTime?.Hours + ' h'}{' '}
              {totalTime?.Minutes > 0 && totalTime?.Minutes + ' min'}
            </p>
          </div>

          <label className={inputStyles.wrapper} htmlFor="rServings">
            <span className={inputStyles.label}>Servings</span>

            <input
              className={`${inputStyles.input} !w-16 ${
                errors.Servings ? 'input-error' : null
              }`}
              type="number"
              id="rServings"
              {...register('Servings', {
                valueAsNumber: true,
                min: 0,
              })}
            />
          </label>
          {errors.Servings && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <p className={`${inputStyles.label} !text-base-color-1 mt-3`}>
            Ingredients
          </p>
          <IngredientsForm
            ingredients={ingredients}
            inputStyles={inputStyles}
            onSaveIng={addIngredient}
            onDeleteIng={deleteIngredient}
            onSetErr={setIngErr}
            onClearErr={clearIngErr}
            emptyNames={ingNameErrors}
          />

          <p className={`${inputStyles.label} !text-base-color-1 mt-3`}>
            Directions
          </p>
          <DirectionsForm
            directions={recipe?.DirectionList}
            inputStyles={inputStyles}
            onSaveStep={addStep}
            onDeleteStep={deleteStep}
          />

          <div className="flex justify-center w-full">
            <button
              className="h-10 text-lg btn-base w-36 font-title-text"
              type="submit">
              Save Recipe
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default RecipeForm;
