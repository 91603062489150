import React, { useRef, useState } from 'react';

import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search_px.svg';
import SearchFilter, {
  CATEGORIES,
  DIRECTIONS,
  INGREDIENTS,
  TITLE,
} from './SearchFilter';
import axios from '../auth';
import SearchResults from './SearchResults';

const Searchbar = () => {
  const searchRef = useRef();
  const titleRef = useRef();
  const catRef = useRef();
  const ingRef = useRef();
  const dirRef = useRef();
  const searchIconRef = useRef();
  const [showSearchFilter, setShowSearchFilter] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [err, setErr] = useState('');

  let timeout;
  const searchOnType = () => {
    clearTimeout(timeout);

    if (!searchRef.current || searchRef.current.value === '') {
      resetSearch();
      return;
    }

    timeout = setTimeout(() => {
      resetSearch();
      fetchSearchResults();
    }, 2000);
  };

  const fetchSearchResults = () => {
    const conditions = [];

    if (titleRef.current?.checked) {
      conditions.push(TITLE);
    }
    if (catRef.current?.checked) {
      conditions.push(CATEGORIES);
    }
    if (ingRef.current?.checked) {
      conditions.push(INGREDIENTS);
    }
    if (dirRef.current?.checked) {
      conditions.push(DIRECTIONS);
    }
    axios()
      .post(`/secured/search?q=${searchRef.current.value}`, { conditions })
      .then((resp) => {
        setSearchResult(resp.data.results);
        if (resp.data.results.length === 0) {
          setErr('empty_result');
        }
      })
      .catch(() => setErr('server_error'))
      .finally(() => setShowSearchFilter(false));
  };

  const handleToggleFilter = () => {
    resetSearch();
    setShowSearchFilter((prev) => !prev);
    if (!titleRef.current) {
      titleRef.current.checked = true;
    }
  };

  const resetSearch = () => {
    setSearchResult([]);
    setErr('');
  };

  const handleSearch = () => {
    clearTimeout(timeout);

    if (!searchRef.current || searchRef.current.value === '') {
      resetSearch();
      return;
    }

    fetchSearchResults();
  };

  const handleClickOutsideSearch = (target) => {
    if (target !== searchRef.current && target !== searchIconRef.current) {
      resetSearch();
    }
  };

  const handleCloseSearch = () => {
    resetSearch();
    searchRef.current.value = '';
  };

  return (
    <div className="relative w-full md:w-80">
      <div className="group">
        <span className="absolute top-0 left-0 justify-end flex-shrink-0 flex-center h-navbar-icon-size w-navbar-icon-size">
          <SearchIcon
            ref={searchIconRef}
            className="navbar-svg !text-base-color-2 p-0.5 group-focus-within:!text-base-color-1"
            style={{ width: 20 }}
          />
        </span>

        <span className="flex items-center w-full gap-2">
          <label className="w-full">
            <input
              id="searchBar"
              ref={searchRef}
              className="w-full input-style h-navbar-icon-size p-left-navbar-icon-size"
              onChange={searchOnType}
            />
          </label>
          <span className="flex-shrink-0 flex-center h-navbar-icon-size w-navbar-icon-size">
            <FilterIcon
              className={`cursor-pointer navbar-svg hover:!text-base-color-1 ${
                showSearchFilter ? '!text-base-color-1' : '!text-base-color-2'
              }`}
              style={{ width: 20 }}
              onClick={handleToggleFilter}
            />
          </span>
        </span>
      </div>
      <SearchFilter
        ref={{ titleRef, catRef, ingRef, dirRef }}
        className={!showSearchFilter ? 'hidden' : null}
        onSearch={handleSearch}
      />
      {searchResult.length > 0 && (
        <SearchResults
          data={searchResult}
          onClickOutside={handleClickOutsideSearch}
          closeSearch={handleCloseSearch}
        />
      )}
      {err !== '' && (
        <div className="search-box-wrapper">
          <p className="italic text-gray-500">
            {err === 'empty_result'
              ? '0 recipes found'
              : 'Error: something went wrong...'}
          </p>
        </div>
      )}
    </div>
  );
};

export default Searchbar;
