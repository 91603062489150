import React from 'react';

export const [TITLE, CATEGORIES, INGREDIENTS, DIRECTIONS] = [
  'title',
  'categories',
  'ingredients',
  'directions',
];

const SearchFilter = React.forwardRef(({ className, onSearch }, ref) => {
  const { titleRef, catRef, ingRef, dirRef } = ref;

  const preventEmptyConditions = (field) => {
    if (
      !catRef.current?.checked &&
      !ingRef.current?.checked &&
      !dirRef.current?.checked &&
      !titleRef.current?.checked
    ) {
      switch (field) {
        case TITLE:
          titleRef.current.checked = true;
          break;
        case CATEGORIES:
          catRef.current.checked = true;
          break;
        case INGREDIENTS:
          ingRef.current.checked = true;
          break;
        case DIRECTIONS:
          dirRef.current.checked = true;
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={`search-box-wrapper ${className}`}>
      <p className="w-full text-gray-500 border-b border-gray-300">
        Search in:
      </p>

      <div className="flex flex-wrap gap-x-3 gap-y-2 !py-2">
        <label
          className="flex items-center gap-1 cursor-pointer"
          htmlFor="titleBox">
          <input
            defaultChecked={titleRef.current?.checked || true}
            type="checkbox"
            id="titleBox"
            className="checkbox-style peer"
            ref={titleRef}
            onChange={() => preventEmptyConditions(TITLE)}
          />
          Title
        </label>
        <label
          className="flex items-center gap-1 cursor-pointer"
          htmlFor="catBox">
          <input
            defaultChecked={catRef.current?.checked}
            type="checkbox"
            id="catBox"
            className="checkbox-style peer"
            ref={catRef}
            onChange={() => preventEmptyConditions(CATEGORIES)}
          />
          Categories
        </label>
        <label
          className="flex items-center gap-1 cursor-pointer"
          htmlFor="ingBox">
          <input
            defaultChecked={ingRef.current?.checked}
            type="checkbox"
            id="ingBox"
            className="checkbox-style peer"
            ref={ingRef}
            onChange={() => preventEmptyConditions(INGREDIENTS)}
          />
          Ingredients
        </label>
        <label
          className="flex items-center gap-1 cursor-pointer"
          htmlFor="dirBox">
          <input
            defaultChecked={dirRef.current?.checked}
            type="checkbox"
            id="dirBox"
            className="checkbox-style peer"
            ref={dirRef}
            onChange={() => preventEmptyConditions(DIRECTIONS)}
          />
          Directions
        </label>
      </div>
      <div className="flex items-center justify-around w-full pb-1.5">
        <span className="text-gray-500 transition-all duration-500 ease-in-out cursor-pointer border-y border-app-white hover:border-base-color-2 hover:text-text-color font-base-text">
          advanced search
        </span>
        <button className="btn-base" onClick={onSearch}>
          search
        </button>
      </div>
    </div>
  );
});

export default SearchFilter;
