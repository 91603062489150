import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import axios from '../auth';
import { ReactComponent as ForkSvg } from '../../../assets/icons/fork.svg';
import { ReactComponent as KnifeSvg } from '../../../assets/icons/knife.svg';

const Register = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const userRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const password2Ref = useRef();
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [registerErr, setRegisterErr] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
    window.addEventListener('resize', () =>
      setWindowHeight(window.innerHeight)
    );
  }, []);

  const register = () => {
    setRegisterErr('');

    if (userRef.current.value === '') {
      setRegisterErr('empty_username');
      return;
    }
    const regexPattern = /^[a-zA-Z0-9._-]+$/g;
    if (!regexPattern.test(userRef.current.value)) {
      setRegisterErr('username_format');
      return;
    }
    if (emailRef.current.value === '') {
      setRegisterErr('empty_email');
      return;
    }
    if (passwordRef.current.value === '') {
      setRegisterErr('empty_password');
      return;
    }
    if (passwordRef.current.value !== password2Ref.current.value) {
      setRegisterErr('passwords_not_match');
      return;
    }

    const data = {
      User: userRef.current.value,
      Email: emailRef.current.value,
      Password: passwordRef.current.value,
      RecaptchaToken: recaptchaToken,
    };

    axios()
      .post(`/public/sign_up`, data)
      .then(() => {
        navigate('/categories');
      })
      .catch((err) => {
        const errType = err.response?.data['error_type'];
        if (errType) {
          setRegisterErr(errType);
        } else {
          setRegisterErr('server_error');
        }
        window.grecaptcha.reset();
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    register();
  };

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      register();
    }
  };

  return (
    <div
      className={`w-full gap-5 p-2 !pb-0 main-height md:p-10 flex-center ${
        windowHeight < 860 ? '!h-fit' : null
      }`}>
      <div className="hidden w-28 h-96 text-app-white md:block">
        <ForkSvg />
      </div>
      <div className="max-w-full px-20 py-16 sm:py-4 rounded-full w-[32rem] sm:h-[32rem] bg-app-white shadow-category-shd">
        <h2 className="p-5 py-0 mb-6 text-xl text-center border-b-4 border-dotted font-title-text text-base-color-1 border-base-color-2">
          Sign Up
        </h2>
        <form
          className="flex flex-col items-start gap-1"
          onSubmit={onSubmit}
          onKeyDownCapture={onEnter}>
          <label htmlFor="register_name" className="flex items-center">
            <p className="mr-2 text-right">Username</p>
          </label>
          <input
            className="w-full input-style"
            ref={userRef}
            id="register_name"
          />
          {registerErr === 'duplicate_user' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              This username is already taken.
            </p>
          ) : registerErr === 'username_format' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              Username can only contain letters, numbers, dot, dash and
              underscore.
            </p>
          ) : registerErr === 'empty_username' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              Enter a username.
            </p>
          ) : null}
          <label htmlFor="register_email" className="flex items-center">
            <p className="mr-2 text-right">E-mail</p>
          </label>
          <input
            className="w-full input-style"
            ref={emailRef}
            id="register_email"
          />
          {registerErr === 'duplicate_email' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              This email address is already taken.
            </p>
          ) : registerErr === 'invalid_email' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              Enter a valid e-mail address.
            </p>
          ) : registerErr === 'empty_email' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              Enter an e-mail address.
            </p>
          ) : null}
          <label htmlFor="register_password" className="flex items-center">
            <p className="mr-2 text-right">Password</p>
          </label>
          <input
            type="password"
            className="w-full input-style"
            ref={passwordRef}
            id="register_password"
          />
          <label htmlFor="register_password_2" className="flex items-center">
            <p className="mr-2 text-right">Password again</p>
          </label>
          <input
            type="password"
            className="w-full input-style"
            ref={password2Ref}
            id="register_password_2"
          />
          {registerErr === 'passwords_not_match' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              The passwords do not match.
            </p>
          ) : registerErr === 'empty_password' ? (
            <p className="w-full mb-2 text-sm italic leading-3 text-base-color-1">
              Enter a password.
            </p>
          ) : registerErr === 'recaptcha_check' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              Verify that you are not a robot.
            </p>
          ) : registerErr === 'server_error' ? (
            <p className="w-full mb-2 text-sm italic text-base-color-1">
              Oops, something went wrong.
            </p>
          ) : null}
          <p className="w-full text-sm text-center">
            Already have an account?
            <span className="ml-1 link-text" onClick={() => navigate('/login')}>
              Log in
            </span>
          </p>

          <div className="flex w-full flex-center">
            <ReCAPTCHA
              sitekey={siteKey}
              onChange={(value) => setRecaptchaToken(value)}
            />
          </div>
          <div className="w-full mt-2 flex-center">
            <button type="submit" className="w-32 mt-2 btn-base">
              Sign Up
            </button>
          </div>
        </form>
      </div>
      <div className="hidden w-28 h-96 text-app-white md:block">
        <KnifeSvg />
      </div>
    </div>
  );
};

export default Register;
