import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useOutsideAlerter from '../../common/OutsidClickDetecter';
import { convertToLabel } from '../../common/Utils';

const SearchResults = ({ data, onClickOutside, closeSearch }) => {
  const navigate = useNavigate();
  const clickDetecterRef = useRef();
  useOutsideAlerter(clickDetecterRef, onClickOutside);

  const handleSelect = (recipe) => {
    closeSearch();
    navigate(`/recipes/${recipe.id}-${convertToLabel(recipe.title)}`);
  };

  return (
    <div className="search-box-wrapper" ref={clickDetecterRef}>
      <ul className="p-1 !pt-0">
        {data.map((res) => (
          <li
            key={res.id}
            onClick={() => handleSelect(res)}
            className="px-1 border-b cursor-pointer border-b-gray-300 hover:border-base-color-2 hover:bg-base-color-2 hover:bg-opacity-10">
            {res.title}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchResults;
