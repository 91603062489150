import React from 'react';

import { ReactComponent as PlusIcon } from './../../../assets/icons/plus_px.svg';

const NewRecipe = ({ onMouseLeave, onClick }) => {
  return (
    <div
      className="relative flex-grow-0 flex-shrink-0 h-56 p-3 overflow-hidden rounded-sm group w-44 bg-app-white shadow-category-shd flex-center"
      onClick={onClick}>
      <div className="category-card hover:cursor-pointer !p-0 !shadow-none flex-center !w-full !h-full rounded-md border-2 border-dotted group-[:nth-child(3n-2)]:!border-base-color-1 group-[:nth-child(3n-1)]:!border-base-color-2 group-[:nth-child(3n)]:!border-base-color-3">
        <div
          className="flex-center !w-category-width !h-category-height"
          onMouseLeave={onMouseLeave}>
          <span className="plus-icon-wrapper icon-container !rounded-full !text-app-white z-10 group-[:nth-child(3n-2)]:!bg-base-color-1 group-[:nth-child(3n-1)]:!bg-base-color-2 group-[:nth-child(3n)]:!bg-base-color-3">
            <PlusIcon
              id="plusIcon2"
              className="four-5th-size"
              style={{ width: 20 }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewRecipe;
