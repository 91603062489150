import React from 'react';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  // TODO fix background bugs
  return (
    <>
      {/* <div className="h-footer-height"></div> */}
      <section className="absolute bottom-0 left-0 w-full h-24">
        <div className="w-full h-full relative !z-20 flex items-center flex-wrap">
          <div className="w-full h-[200%] bg-opacity-100 bg-app-white skew-y-3 !z-0 absolute top-0 right-0 shadow-footer-shd border-t-4 border-base-color-3"></div>
          <div className="flex w-full">
            <ul className="flex gap-4 m-2 !mt-4 skew-y-0 font-title-text">
              <li
                className="text-sm cursor-pointer hover:underline underline-offset-8 w-fit"
                onClick={() => navigate('privacy_policy')}>
                Privacy Policy
              </li>
              <li
                className="text-sm cursor-pointer hover:underline underline-offset-8 w-fit"
                onClick={() => navigate('whats_new')}>
                What's new?
              </li>
            </ul>
          </div>
          <p className="z-30 w-full m-2 text-sm text-center skew-y-0 font-base-text text-base-color-3">
            © my-menu-manual.com - all rights reserved
          </p>
        </div>
      </section>
    </>
  );
};

export default Footer;
