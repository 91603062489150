import React, { useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import NewCategory from './NewCategory';
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from './../../../assets/icons/save.svg';
import { CardIconWrapper } from './../../common/IconWrappers';
import { ReactComponent as TrashIcon } from './../../../assets/icons/trash.svg';
import { Confirm, convertToLabel } from '../../common/Utils';
import { ReactComponent as ArticleIcon } from './../../../assets/icons/article.svg';
import { InnerShadow } from '../../common/Styles';
import axios from '../auth';
import { UserContext } from '../../../App';
import EditCategory from './EditCategory';

const Categories = ({ isEdit }) => {
  const { categories, changeCategory } = useContext(UserContext);
  const [editCatID, setEditCatID] = useState(0);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onCategoryMouseLeave = (e) => {
    e.preventDefault();
    e.target
      .getElementsByClassName('label')[0]
      ?.classList?.add('category-card-reverse');
  };

  const deleteCategory = (id) => {
    axios()
      .delete(`/secured/categories/${id}`)
      .then(() => changeCategory())
      .catch((error) => console.error(error));
  };

  const toggleEdit = () => {
    if (['/categories', '/categories/'].includes(pathname)) {
      navigate('edit');
    } else {
      navigate('/categories');
      setEditCatID(0);
    }
  };

  const listRecipes = (selectedCat) => {
    if (['/categories', '/categories/'].includes(pathname)) {
      navigate(`${selectedCat.id}-${convertToLabel(selectedCat.label)}`);
      changeCategory();
    }
  };

  const handleUpdate = () => {
    setEditCatID(0);
    changeCategory();
  };

  return (
    <div className="relative mb-[var(--footer-height)] sm:mb-0">
      {/* <div className="absolute w-full white-bg-gradient h-72"></div> */}

      <div
        onClick={toggleEdit}
        className="absolute z-30 ml-auto btn-edit top-4 right-3 hover:bg-base-color-2">
        {isEdit ? (
          <SaveIcon className="four-5th-size" style={{ width: 20 }} />
        ) : (
          <EditIcon className="four-5th-size" style={{ width: 20 }} />
        )}
      </div>
      <div className="relative z-10 p-10 pt-14 grid grid-cols-[repeat(auto-fill,_var(--category-width))] gap-8 justify-center card-variations">
        {categories && (
          <>
            {isEdit && (
              <NewCategory
                onMouseLeave={(e) => onCategoryMouseLeave(e)}
                onAddCategory={changeCategory}
              />
            )}
            {categories.map((cat) => (
              <div
                key={cat.ID}
                className={`category-card flex-center ${
                  isEdit ? null : 'cursor-pointer'
                }`}
                onMouseLeave={(e) => onCategoryMouseLeave(e)}
                onClick={() =>
                  listRecipes({
                    id: cat.ID,
                    label: cat.Label,
                    img: cat.Img,
                  })
                }>
                {editCatID !== 0 && editCatID === cat.ID ? (
                  <EditCategory
                    cat={categories.filter((c) => c.ID === editCatID)[0]}
                    onUpdate={handleUpdate}
                    onCancel={() => setEditCatID(0)}
                  />
                ) : (
                  <div className="content-wrapper">
                    {isEdit && cat.ID !== 0 && (
                      <CardIconWrapper
                        onClick={[
                          () => setEditCatID(cat.ID),
                          () => Confirm(() => deleteCategory(cat.ID)),
                        ]}>
                        <EditIcon style={{ width: 20 }} />
                        <TrashIcon style={{ width: 20 }} />
                      </CardIconWrapper>
                    )}
                    {cat.Img === '' ? (
                      <div className="full-size card-bg-variations">
                        <div className="w-full h-full pb-2 border flex-center card-color-variations">
                          <ArticleIcon className="w-10 h-10" />
                        </div>
                      </div>
                    ) : (
                      <>
                        <InnerShadow className="!rounded-3xl" />
                        <img
                          className="cat-img"
                          src={`/img/categories/${cat.Img}`}
                          alt={`category_${cat.ID}`}
                          style={{ width: 200, height: 200 }}
                        />
                      </>
                    )}
                    <p
                      className={`absolute left-0 -bottom-2 w-full h-14 bg-app-white flex justify-center text-center break-words ${
                        isEdit ? 'edit-label' : 'label'
                      }`}>
                      {cat.Label}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Categories;
