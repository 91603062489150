import { createContext, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Header from './components/pages/Header';
import Categories from './components/pages/categories/Categories';
import Recipes from './components/pages/recipes/Recipes';
import RecipeDetails from './components/pages/recipes/RecipeDetails';
import NewRecipeForm from './components/pages/recipes/form/NewRecipeForm';
import EditRecipeForm from './components/pages/recipes/form/EditRecipeForm';
import Footer from './components/pages/Footer';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import ScrollToTop from './components/common/ScrollToTop';
import Login from './components/pages/user/Login';
import { authenticateUser } from './components/pages/auth';
import NotFound from './components/pages/NotFound';
import Register from './components/pages/user/Register';
import ConfirmEmail from './components/pages/ConfirmEmail';
import axios from './components/pages/auth';
import WhatsNew from './components/pages/WhatsNew';

export const UserContext = createContext({
  data: {},
  currentUser: {},
  categories: {},
});

function App() {
  const [currentUser, setCurrentUser] = useState();
  const [categories, setCategories] = useState();
  const [catsChanged, setCatsChanged] = useState(false);

  const changeCurrentUser = () => {
    const jwtToken = localStorage.getItem('token');

    if (jwtToken) {
      const result = authenticateUser();

      if (result) {
        setCurrentUser(result);
      } else {
        removeCurrentUser();
      }
    }
  };

  if (!currentUser && authenticateUser()) {
    changeCurrentUser();
  }

  const removeCurrentUser = () => {
    setCurrentUser(null);
    setCategories(null);
  };

  useEffect(() => {
    if (!categories || (currentUser && catsChanged)) {
      axios()
        .get('/secured/categories')
        .then((resp) => {
          setCategories(resp.data.Categories);
        })
        .catch((error) => console.error(error));
    }

    return () => setCatsChanged(false);
  }, [currentUser, catsChanged, categories]);

  const changeCategory = () => {
    setCatsChanged(true);
  };

  return (
    <>
      <UserContext.Provider
        value={{
          currentUser,
          changeCurrentUser,
          removeCurrentUser,
          categories,
          changeCategory,
        }}>
        <div className="relative w-full min-h-screen overflow-hidden">
          <Header />
          <div id="main-wrapper" className="w-full m-navbar-height sm:m-main-y">
            <ScrollToTop />
            <Routes>
              <Route
                path=""
                element={
                  currentUser ? (
                    <Navigate to="categories" replace />
                  ) : (
                    <Navigate to="login" replace />
                  )
                }
              />
              <Route
                path="login"
                element={
                  <PublicRoute>
                    <Login />
                  </PublicRoute>
                }
              />
              <Route
                path="register"
                element={
                  <PublicRoute>
                    <Register />
                  </PublicRoute>
                }
              />
              <Route path="categories">
                <Route
                  index={true}
                  element={
                    <ProtectedRoute>
                      <Categories isEdit={false} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="edit"
                  element={
                    <ProtectedRoute>
                      <Categories isEdit={true} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":cid"
                  element={
                    <ProtectedRoute>
                      <Recipes isEdit={false} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":cid/edit"
                  element={
                    <ProtectedRoute>
                      <Recipes isEdit={true} />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="recipes">
                <Route
                  path="new"
                  element={
                    <ProtectedRoute>
                      <NewRecipeForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":rid"
                  element={
                    <ProtectedRoute>
                      <RecipeDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":rid/edit"
                  element={
                    <ProtectedRoute>
                      <EditRecipeForm />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="confirm_email" element={<ConfirmEmail />} />
              <Route path="privacy_policy" element={<PrivacyPolicy />} />
              <Route path="whats_new" element={<WhatsNew />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </UserContext.Provider>
    </>
  );
}

const PublicRoute = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  if (currentUser) {
    return <Navigate to="/404" />;
  }
  return children;
};

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useContext(UserContext);

  if (!currentUser) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

export default App;
