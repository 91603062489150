import React, { Fragment, useEffect, useState } from 'react';
import axios from './../auth';
import { useLocation, useNavigate } from 'react-router-dom';

import { Confirm, GetIDFromPathName, convertToLabel } from '../../common/Utils';
import { ReactComponent as ArticleIcon } from './../../../assets/icons/article.svg';
import { ReactComponent as EditIcon } from './../../../assets/icons/edit.svg';
import { ReactComponent as SaveIcon } from './../../../assets/icons/save.svg';
import { ReactComponent as TrashIcon } from './../../../assets/icons/trash.svg';
import { CardIconWrapper } from '../../common/IconWrappers';
import NewRecipe from './NewRecipeCard';
import { InnerShadow } from '../../common/Styles';
import { DeleteRecipe } from './form/EditRecipeForm';
import { ErrorMessage } from '../../common/Notification';

const Recipes = ({ isEdit }) => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState();
  const [category, setCategory] = useState();
  const { pathname } = useLocation();
  const catID = GetIDFromPathName(pathname, isEdit ? 2 : 1);
  const [dataChanged, setDataChanged] = useState(0);
  const [err, setErr] = useState(false);

  useEffect(() => {
    console.log('hiba', err);
  }, [err]);

  const getCategory = () => {
    axios()
      .get(`/secured/categories/${catID}`)
      .then((resp) => {
        setCategory(resp.data.Category);
      })
      .catch(() => setErr(true));
  };

  const getRecipes = () => {
    axios()
      .get(`/secured/recipes?cat_id=${catID}`)
      .then((resp) => {
        setRecipes(resp.data.Recipes);
      })
      .catch(() => setErr(true));
  };

  useEffect(() => {
    setErr(false);
    getRecipes();
    getCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dataChanged, catID]);

  const toggleEdit = () => {
    const pathParts = pathname.split('/');

    if (pathParts[pathParts.length - 1] === 'edit') {
      navigate(pathname.slice(0, -5));
    } else {
      navigate('edit');
    }
  };

  const goToRecipePage = (id, title) => {
    if (!pathname.includes('edit')) {
      navigate(`/recipes/${id}-${convertToLabel(title)}`);
      getRecipes();
    }
  };

  const onCardMouseLeave = (e) => {
    e.preventDefault();
    e.target
      .getElementsByClassName('label')[0]
      ?.classList?.add('category-card-reverse');
  };

  const goToRecipeForm = () => {
    navigate(`/recipes/new`, { state: { category: pathname.slice(12, -5) } });
  };

  return (
    <div className="relative pb-[var(--footer-height)] sm:pb-0">
      {category && recipes ? (
        <Fragment>
          <div
            onClick={toggleEdit}
            className="absolute z-30 ml-auto btn-edit top-4 right-3">
            {isEdit ? (
              <SaveIcon className="four-5th-size" style={{ width: 20 }} />
            ) : (
              <EditIcon className="four-5th-size" style={{ width: 20 }} />
            )}
          </div>
          <section
            id="recipesHeader"
            className="relative flex flex-wrap items-center justify-center w-full gap-6 px-8 pt-5 pb-3 bg-app-white h-28 sm:justify-start">
            <div className="absolute -bottom-20 right-0 w-full h-[400%] skew-y-6 bg-app-white"></div>

            {category?.Img !== '' && (
              <div className="rounded-sm rounded-bl-lg overflow-hidden relative max-h-full aspect-[3/2] hidden sm:block shadow-category-sharp-shd">
                <InnerShadow className="rounded-sm rounded-bl-lg opacity-70" />
                <img
                  className="object-cover full-size"
                  src={`/img/categories/${category?.Img}`}
                  alt={`category_${category?.Label}`}
                  style={{ width: 200, height: 200 }}
                />
              </div>
            )}

            <h2 className="z-10 pt-2 text-4xl font-title-text text-base-color-3">
              {category?.Label}
            </h2>
          </section>
          <section className="flex flex-wrap justify-center mx-10 my-5 gap-x-6 gap-y-10 recipe-text-variations ">
            {isEdit && (
              <NewRecipe
                onMouseLeave={(e) => onCardMouseLeave(e)}
                onClick={goToRecipeForm}
              />
            )}
            {recipes?.map((r) => (
              <div
                key={r.ID}
                className={`group w-44 h-56 bg-app-white flex-shrink-0 flex-grow-0 p-3 rounded-sm relative shadow-category-shd overflow-hidden group ${
                  isEdit ? null : 'cursor-pointer hover:shadow-recipe-hover-shd'
                }`}
                onClick={() => goToRecipePage(r.ID, r.Title)}>
                {isEdit && (
                  <CardIconWrapper
                    onClick={[
                      () =>
                        navigate(
                          `/recipes/${r.ID}-${convertToLabel(r.Title)}/edit`
                        ),
                      () =>
                        Confirm(() =>
                          DeleteRecipe(r.ID, () =>
                            setDataChanged((prev) => prev + 1)
                          )
                        ),
                    ]}>
                    <EditIcon className="w-5/6 h-5/6" style={{ width: 20 }} />
                    <TrashIcon className="w-5/6 h-5/6" style={{ width: 20 }} />
                  </CardIconWrapper>
                )}

                <div className="relative w-full h-24 rounded-sm">
                  <InnerShadow className="!rounded-sm opacity-30" />
                  {r.Img === '' ? (
                    <div className="full-size flex-center">
                      <span className="w-1/2 h-1/2">
                        <ArticleIcon />
                      </span>
                    </div>
                  ) : (
                    <>
                      <div className="card-inner-shd"></div>
                      <img
                        className="object-cover full-size"
                        src={`/img/recipes/${r.Img}`}
                        alt={`category_${r.Title}`}
                        style={{ width: 200, height: 200 }}
                      />
                    </>
                  )}
                </div>

                <h3
                  className={`font-title-text font-bold my-2 leading-tight border-b-2 border-dotted group-[:nth-child(3n-2)]:border-base-color-1 group-[:nth-child(3n-1)]:border-base-color-2 group-[:nth-child(3n)]:border-base-color-3 pb-1 ${
                    isEdit ? null : 'group-hover:underline'
                  }`}>
                  {r.Title}
                </h3>

                <p className="leading-tight text-text-color">{r.Directions}</p>

                {/* paper corner effect */}
                <div
                  className={`w-16 h-16 absolute right-0 bottom-0 paper-corner z-20 opacity-0 transition-opacity duration-0 ${
                    isEdit ? null : 'group-hover:opacity-100'
                  }`}></div>
                <div className="absolute bottom-0 left-0 w-full h-12 white-bg-gradient-180"></div>
              </div>
            ))}
          </section>
        </Fragment>
      ) : err ? (
        <ErrorMessage />
      ) : null}
    </div>
  );
};

export default Recipes;
