import React from 'react';

import { ReactComponent as SadEmojiIcon } from '../../assets/icons/sad_emoji.svg';

const NotFound = () => {
  return (
    <>
      <section
        id="recipesHeader"
        className="relative w-full h-[25vh] bg-app-white">
        <div className="absolute -bottom-20 right-0 w-full h-[200%] -skew-y-6 bg-app-white">
          <div className="flex items-end justify-center w-full h-full gap-3 pb-3 text-2xl font-bold items- font-title-text text-base-color-1">
            <div className="flex flex-col items-center">
              <SadEmojiIcon className="mb-5" style={{ width: 100 }} />
              <p className="text-4xl">404</p>
              <p className="min-w-fit">Page Not Found</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
