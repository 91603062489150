import React, { useEffect, useState } from 'react';

import { InnerShadow } from '../../../common/Styles';
import { CardIconWrapper } from '../../../common/IconWrappers';
import { ReactComponent as TrashIcon } from './../../../../assets/icons/trash.svg';

const UploadImage = ({ inputStyles, img, imgName, onSelect, onReset }) => {
  const [name, setName] = useState(imgName);
  const selectImg = (e) => {
    e.preventDefault();
    onSelect(e.target.files[0]);
  };

  useEffect(() => {
    if (imgName) {
      setName(imgName);
    }
  }, [imgName]);

  const resetImg = () => {
    document.getElementById('rUploadImg').value = null;
    setName('');
    onReset();
  };

  return (
    <div className={`${inputStyles.wrapper.replace('group', '')} !w-full`}>
      <span className={`${inputStyles.label} flex-shrink-0`}>Image</span>
      {(name || img) && (
        <div className="relative overflow-hidden rounded-sm aspect-square h-72">
          <InnerShadow className="rounded-sm" />

          <img
            src={img ? URL.createObjectURL(img) : `/img/recipes/${name}`}
            alt="preview"
            className="object-cover full-size"
          />
          <CardIconWrapper onClick={[resetImg]}>
            <TrashIcon className="w-5/6 h-5/6" style={{ width: 20 }} />
          </CardIconWrapper>
        </div>
      )}
      <div className="relative group w-32 h-[33px] cursor-pointer">
        <input
          type="file"
          id="rUploadImg"
          className="absolute z-20 opacity-0 full-size"
          accept="image/png, image/gif, image/jpeg"
          onChange={selectImg}
        />
        <button
          type="button"
          className="btn-edit rounded-sm text-sm !w-full group-hover:bg-opacity-100 group-hover:icon-bg-hover">
          + Select Image
        </button>
      </div>
    </div>
  );
};

export default UploadImage;
