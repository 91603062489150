import React, { useRef, useState } from 'react';
import { ReactComponent as PlusIcon } from './../../../assets/icons/plus_px.svg';
import { ReactComponent as NewImgIcon } from './../../../assets/icons/new_img_px.svg';
import { ReactComponent as TrashIcon } from './../../../assets/icons/trash.svg';
import axios from './../auth';
import { CardIconWrapper } from '../../common/IconWrappers';
import { InnerShadow } from '../../common/Styles';

const NewCategory = ({ onMouseLeave, onAddCategory }) => {
  const [isEdit, setIsEdit] = useState(false);
  const labelRef = useRef();
  const [img, setImg] = useState();
  const [err, setErr] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!labelRef.current?.value || labelRef.current?.value === '') {
      setErr('label');
      return;
    }

    const form = new FormData();
    form.append('label', labelRef.current.value);
    if (img) {
      form.append('img', img);
    }

    axios()
      .post('/secured/categories/new', form)
      .then(() => {
        resetImg();
        labelRef.current.value = '';
        setIsEdit(false);
        onAddCategory();
      })
      .catch((error) => setErr(error.response.data?.error_type));
  };

  const selectImg = (e) => {
    e.preventDefault();
    setImg(e.target.files[0]);
  };

  const resetImg = () => {
    setImg(null);
    document.getElementById('uploadImg').value = null;
  };

  return (
    <>
      {isEdit ? (
        <div
          className="cursor-pointer category-card flex-center group"
          onMouseLeave={onMouseLeave}>
          <div className="flex flex-col items-center gap-1 cursor-default content-wrapper bg-app-white">
            <div className="flex-grow-0 w-full p-2 pb-0 h-1/2">
              {img ? (
                <>
                  <InnerShadow />
                  <div className="absolute top-0 left-0 card-bg-gradient !z-10 w-full h-full"></div>
                  <img
                    src={URL.createObjectURL(img)}
                    alt="preview"
                    className="!z-0 object-cover absolute top-0 left-0"
                  />
                  <CardIconWrapper onClick={[resetImg]}>
                    <TrashIcon className="w-5/6 h-5/6" style={{ width: 20 }} />
                  </CardIconWrapper>
                </>
              ) : (
                <label
                  htmlFor="uploadImg"
                  className="w-full h-full border border-dotted rounded-b-sm cursor-pointer flex-center border-base-color-1 rounded-t-md">
                  <NewImgIcon
                    className="!text-base-color-1"
                    style={{ width: 20 }}
                  />
                </label>
              )}
              <input
                type="file"
                id="uploadImg"
                className="absolute hidden"
                accept="image/png, image/gif, image/jpeg"
                onChange={(e) => selectImg(e)}
              />
            </div>
            <div className="z-20 px-2">
              <input
                className="w-full h-6 text-sm input-style"
                ref={labelRef}
                maxLength={25}
                placeholder="enter a category"
              />
            </div>
            {err && (
              <p className="!-mt-1 !ml-5 z-20 w-full h-6 error-msg-sm font-semibold">
                {err === 'label'
                  ? 'Enter a category.'
                  : err === 'img'
                  ? 'Image not saved.'
                  : 'Category not saved.'}
              </p>
            )}
            <div className="flex gap-2 mb-2">
              <button
                type="button"
                className="z-20 btn-base-var"
                onClick={() => setIsEdit(false)}>
                back
              </button>
              <button
                type="button"
                className="z-20 btn-base"
                onClick={(e) => handleSubmit(e)}>
                save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="cursor-pointer category-card flex-center group"
          onMouseLeave={onMouseLeave}
          onClick={() => setIsEdit(true)}>
          <span className="plus-icon-wrapper">
            <PlusIcon
              className="four-5th-size text-base-color-1"
              style={{ width: 20 }}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default NewCategory;
