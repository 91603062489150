import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import { ReactComponent as TrashIcon } from './../../../../assets/icons/trash.svg';

const DirectionsForm = ({
  directions,
  inputStyles,
  onSaveStep,
  onDeleteStep,
}) => {
  const { control, register, reset } = useForm({
    defaultValues: {
      Directions: [{ step: '' }],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'Directions',
  });

  const deleteStep = (i) => {
    remove(i);
    onDeleteStep(i);
  };

  useEffect(() => {
    const newDirections = [];
    if (directions) {
      for (let i = 0; i < directions.length; i++) {
        const step = { step: directions[i] };
        newDirections.push(step);
        onSaveStep(directions[i], i);
      }
      reset({ Directions: newDirections });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directions, reset]);

  return (
    <div className="mb-4">
      {fields.map((f, i) => (
        <div key={f.id} className={`${inputStyles.wrapper} mb-4`}>
          <label className={inputStyles.label} htmlFor={`step${i}`}>
            Step {i + 1}
          </label>
          <div className="flex items-center flex-grow w-full gap-1 sm:w-auto">
            <textarea
              className={`${inputStyles.input} flex-grow min-h-[6rem] focus:outline-base-color-3`}
              name={`Directions.${i}.step`}
              id={`step${i}`}
              {...register(`Directions.${i}.step`)}
              onChange={(e) => onSaveStep(e.target.value, i)}
            />
            <button
              type="button"
              className="btn-edit !p-0 !rounded-full"
              onClick={() => deleteStep(i)}>
              <TrashIcon style={{ width: '20px' }} />
            </button>
          </div>
        </div>
      ))}
      <button
        type="button"
        className="btn-edit rounded-sm text-sm !w-fit ml-3 px-2 py-1"
        onClick={() => {
          append({ step: '' });
        }}>
        + Add Step
      </button>
    </div>
  );
};

export default DirectionsForm;
