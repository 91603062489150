export const convertToLabel = (text) => {
  let cleanedString = text
    .normalize('NFD') // remove accents 1
    .replace(/[\u0300-\u036f]/g, '') // 2
    .replace(/[^a-zA-Z0-9 ]/g, '') // remove non-alphanumeric characters
    .toLowerCase() // convert to lowercase
    .replace(/ /g, '_'); // replace spaces with underscores

  return cleanedString;
};

export const removeExtraSpaces = (str) => {
  return str.replace(/\s+/g, ' ');
};

export const GetIDFromPathName = (pathName, partNumDesc) => {
  const pathParts = pathName.split('/');
  const id = pathParts[pathParts.length - partNumDesc].split('-')[0];
  return id;
};

export const GetPathPart = (pathName, partNumDesc) => {
  const pathParts = pathName.split('/');
  const part = pathParts[pathParts.length - partNumDesc];
  return part;
};

export const Confirm = (onConfirm, msg) => {
  if (window.confirm(msg || 'Do you really want to delete it?')) {
    onConfirm();
  }
};

export const Capitalize = (str) => {
  const words = str.split(' ');
  let capitalizedStr = '';
  for (const word of words) {
    capitalizedStr += word[0].toUpperCase() + word.slice(1) + ' ';
  }
  return capitalizedStr;
};
