import React from 'react';

export const ErrorMessage = ({ message, hide }) => {
  return (
    <div
      className={`flex-center !bg-base-color-1 text-app-white w-full overflow-hidden mx-auto h-8 rounded-b-full ${
        hide ? 'animate-open-x fill-mode-forwards animation-delay-500' : ''
      }`}>
      {message ? message : 'Oops, something went wrong...'}
    </div>
  );
};

export default Notification;
