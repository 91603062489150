import React, { useEffect, useState } from 'react';

import { ReactComponent as MailUnreadIcon } from '../../assets/icons/mail_unread.svg';

const ConfirmEmail = () => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener('resize', () =>
      setWindowHeight(window.innerHeight)
    );
  }, []);

  return (
    <section
      id="recipesHeader"
      className="relative flex flex-wrap items-center justify-center w-full h-20 gap-6 px-8 pt-5 pb-3 bg-bg-color sm:justify-start">
      <div className="absolute -bottom-20 right-0 w-full h-[800%] skew-y-6 bg-app-white"></div>
      <div
        className={`z-20 w-full h-full text-base-color-1 ${
          windowHeight < 650 ? 'm-main-y-mobile' : null
        }`}>
        <p className="z-20 font-bold sm:text-center font-title-text">
          Confirm your e-mail address to access your account!
        </p>
        <MailUnreadIcon className="mx-auto" style={{ width: 50 }} />
      </div>
    </section>
  );
};

export default ConfirmEmail;
