import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import axios from '../../auth';

import { convertToLabel } from '../../../common/Utils';
import DirectionsForm from './DirectionsForm';
import IngredientsForm, { ConvertAmountToNum } from './IngredientsForm';
import UploadImage from './UploadImage';
import RecipeCategories from './RecipeCategories';
import { UserContext } from '../../../../App';

const inputStyles = {
  wrapper:
    'group flex flex-col sm:flex-row items-start sm:items-center w-full gap-x-3 gap-y-0',
  label:
    'font-title-text text-base-color-2 group-focus-within:text-base-color-3 w-20 sm:text-right mt-1 font-semibold',
  input: 'input-style focus:border-base-color-3 focus:shadow-md',
  timeWrapper: 'flex gap-3 items-center',
};

const RecipeForm = () => {
  const [img, setImg] = useState();
  const [totalTime, setTotalTime] = useState();
  const { categories: allCats } = useContext(UserContext);
  const [selectedCats, setSelectedCats] = useState([]);
  const [ingNameErrors, setIngNameErrors] = useState([]);
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const watchFields = useWatch({
    control,
    PrepTime: {
      Hours: '',
      Minutes: '',
    },
    CookTime: {
      Hours: '',
      Minutes: '',
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (data.Ingredients) {
      const errIndexes = [];

      for (let i = 0; i < data.Ingredients.length; i++) {
        const ing = data.Ingredients[i];

        if (!ing) {
          continue;
        }
        // check if ingredient has empty name
        if (
          !(ing.amount === 0 && ing.unit === '' && ing.name === '') &&
          ing.name === ''
        ) {
          errIndexes.push(i);
        }

        // convert to float
        const numVal = ConvertAmountToNum(ing.amount, () => {});
        data.Ingredients[i] = {
          ...ing,
          amount: Math.round(numVal * 100) / 100,
        };
      }
      if (errIndexes.length > 0) {
        setIngNameErrors(errIndexes);
        return;
      }

      const filteredIngs = data.Ingredients.filter(
        (ing) =>
          ing !== null &&
          !(ing.amount === 0 && ing.unit === '' && ing.name === '')
      );
      data.Ingredients = filteredIngs;
    }

    if (data.Directions) {
      const filteredDirections = data.Directions.filter((dir) => dir !== null);
      data.Directions = filteredDirections;
    }

    const catIDs = [];
    for (const c of selectedCats) {
      catIDs.push(c.ID);
    }
    data.Categories = catIDs;

    const form = new FormData();
    form.append('data', JSON.stringify(data));
    if (img) {
      form.append('img', img);
    }

    const catID = selectedCats[0]?.ID || 0;
    let catPath;
    if (catID !== 0) {
      const catName = allCats.find((obj) => obj.ID === catID)?.Label;
      catPath = `/categories/${catID}-${convertToLabel(catName)}`;
    } else {
      catPath = '/categories/0';
    }

    axios()
      .post('/secured/recipes/new', form)
      .then(() => {
        navigate(catPath);
      })
      .catch((error) => console.error(error));
  };

  const addIngredient = (values, i) => {
    let newIngredients = getValues('Ingredients');

    if (!newIngredients) {
      newIngredients = [];
    }
    newIngredients[i] = values;
    setValue('Ingredients', newIngredients);
  };

  const deleteIngredient = (i) => {
    let newIngredients = getValues('Ingredients');
    if (newIngredients) {
      newIngredients.splice(i, 1);
    } else {
      newIngredients = [];
    }
    setValue('Ingredients', newIngredients);
  };

  const addStep = (value, i) => {
    let newDirections = getValues('Directions');

    if (!newDirections) {
      newDirections = [];
    }
    newDirections[i] = value;
    setValue('Directions', newDirections);
  };

  const deleteStep = (i) => {
    let newDirections = getValues('Directions');
    if (newDirections) {
      newDirections.splice(i, 1);
    } else {
      newDirections = [];
    }
    setValue('Directions', newDirections);
  };

  useEffect(() => {
    setTotalTime({
      Hours:
        (getValues('PrepTime.Hours') || 0) + (getValues('CookTime.Hours') || 0),
      Minutes:
        (getValues('PrepTime.Minutes') || 0) +
        (getValues('CookTime.Minutes') || 0),
    });
  }, [watchFields, getValues]);

  const addCat = (catID) => {
    const newCat = allCats.filter((c) => c.ID === parseInt(catID))[0];
    setSelectedCats((prev) => [...prev, newCat]);
  };

  const removeCat = (catID) => {
    const newCats = selectedCats.filter((c) => c.ID !== catID);
    setSelectedCats(newCats);
  };

  const setIngErr = (field, idx) => {
    setError(`ingredient_${field}_${idx}`, {
      type: field,
      message: `Check the "${field}" field of the ${idx + 1}. ingredient`,
    });
  };

  const clearIngErr = (field, idx) => {
    clearErrors(`ingredient_${field}_${idx}`);
  };

  return (
    <div className="main-wrapper-margin">
      <section className="sm:pb-5 main-wrapper-style">
        <h2 className="main-h2-style">New recipe</h2>

        <form
          className="flex flex-col gap-4 mt-6 mb-2"
          onSubmit={handleSubmit(onSubmit)}>
          <label className={inputStyles.wrapper} htmlFor="rTitle">
            <span className={inputStyles.label}>Title</span>
            <input
              className={`${inputStyles.input} w-full sm:w-auto flex-grow ${
                errors.Title ? 'input-error' : null
              }`}
              id="rTitle"
              {...register('Title', {
                required: 'Enter a recipe name.',
              })}
            />
          </label>
          {errors.Title && (
            <p className="error-msg-sm">{errors.Title.message}</p>
          )}
          <label
            className={`${inputStyles.wrapper} ${
              selectedCats.length > 0 ? 'sm:!items-start' : null
            }`}
            htmlFor="rCategores">
            <span className={inputStyles.label}>Categories</span>
            <RecipeCategories
              recipeCats={selectedCats}
              onAdd={addCat}
              onRemove={removeCat}
              inputStyles={inputStyles}
              allCats={allCats}
            />
          </label>
          {errors.Category && (
            <p className="error-msg-sm">{errors.Category.message}</p>
          )}

          <UploadImage
            inputStyles={inputStyles}
            img={img}
            imgName={''}
            onSelect={(data) => setImg(data)}
            onReset={() => setImg(null)}
          />

          <div className={inputStyles.wrapper}>
            <span className={inputStyles.label}>Prep Time</span>

            <div className={inputStyles.timeWrapper}>
              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.PrepTime?.Hours ? 'input-error' : null
                }`}
                type="number"
                id="rPrepTimeH"
                {...register('PrepTime.Hours', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rPrepTimeH">
                hours
              </label>

              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.PrepTime?.Minutes ? 'input-error' : null
                }`}
                type="number"
                id="rPrepTimeM"
                {...register('PrepTime.Minutes', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rPrepTimeM">
                minutes
              </label>
            </div>
          </div>
          {errors.PrepTime && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <div className={inputStyles.wrapper}>
            <span className={inputStyles.label}>Cook Time</span>

            <div className={inputStyles.timeWrapper}>
              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.CookTime?.Hours ? 'input-error' : null
                }`}
                type="number"
                id="rCookTimeH"
                {...register('CookTime.Hours', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rCookTimeH">
                hours
              </label>

              <input
                className={`${inputStyles.input} !w-16 ${
                  errors.CookTime?.Minutes ? 'input-error' : null
                }`}
                type="number"
                id="rCookTimeM"
                {...register('CookTime.Minutes', {
                  valueAsNumber: true,
                  min: 0,
                })}
              />
              <label className="font-title-text" htmlFor="rCookTimeM">
                minutes
              </label>
            </div>
          </div>
          {errors.CookTime && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <div className={inputStyles.wrapper}>
            <p className={inputStyles.label}>Total Time</p>
            <p>
              {totalTime?.Hours > 0 && totalTime?.Hours + ' h'}{' '}
              {totalTime?.Minutes > 0 && totalTime?.Minutes + ' min'}
            </p>
          </div>

          <label className={inputStyles.wrapper} htmlFor="rServings">
            <span className={inputStyles.label}>Servings</span>

            <input
              className={`${inputStyles.input} !w-16 ${
                errors.Servings ? 'input-error' : null
              }`}
              type="number"
              id="rServings"
              {...register('Servings', {
                valueAsNumber: true,
                min: 0,
              })}
            />
          </label>
          {errors.Servings && (
            <p className="error-msg-sm">The minimum value is 0.</p>
          )}

          <p className={`${inputStyles.label} !text-base-color-1 mt-3`}>
            Ingredients
          </p>
          <IngredientsForm
            ingredients={null}
            inputStyles={inputStyles}
            onSaveIng={addIngredient}
            onDeleteIng={deleteIngredient}
            onSetErr={setIngErr}
            onClearErr={clearIngErr}
            emptyNames={ingNameErrors}
          />

          <p className={`${inputStyles.label} !text-base-color-1 mt-3`}>
            Directions
          </p>
          <DirectionsForm
            directions={null}
            inputStyles={inputStyles}
            onSaveStep={addStep}
            onDeleteStep={deleteStep}
          />

          <div className="flex justify-center w-full">
            <button
              className="h-10 text-lg btn-base w-36 font-title-text"
              type="submit">
              Add Recipe
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default RecipeForm;
