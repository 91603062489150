import React, { useRef, useState } from 'react';
import { ReactComponent as NewImgIcon } from './../../../assets/icons/new_img_px.svg';
import { ReactComponent as TrashIcon } from './../../../assets/icons/trash.svg';
import axios from './../auth';
import { CardIconWrapper } from '../../common/IconWrappers';
import { InnerShadow } from '../../common/Styles';

const EditCategory = ({ cat, onUpdate, onCancel }) => {
  const labelRef = useRef(cat.Label);
  const [img, setImg] = useState();
  const [imgURL, setImgURL] = useState(
    cat.Img !== '' ? `/img/categories/${cat.Img}` : null
  );
  const [imgChanged, setImgChanged] = useState(false);
  const [err, setErr] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!labelRef.current?.value || labelRef.current?.value === '') {
      setErr('label');
      return;
    }

    const form = new FormData();
    form.append('label', labelRef.current.value);
    form.append('imgChanged', imgChanged);
    if (img) {
      form.append('img', img);
    }

    axios()
      .post(`/secured/categories/${cat.ID}/update`, form)
      .then(() => {
        resetImg();
        labelRef.current.value = '';
        onUpdate();
      })
      .catch((err) => {
        setErr(err.response?.data?.error_type);
      });
  };

  const selectImg = (e) => {
    e.preventDefault();
    const imgFile = e.target.files[0];
    setImg(imgFile);
    setImgChanged(true);
    setImgURL(URL.createObjectURL(imgFile));
  };

  const resetImg = () => {
    setImg(null);
    setImgChanged(true);
    setImgURL(null);
    document.getElementById('uploadImg').value = null;
  };

  return (
    <div className="flex flex-col items-center gap-1 cursor-default content-wrapper bg-app-white">
      <div className="flex-grow-0 w-full p-2 pb-0 h-1/2">
        {imgURL ? (
          <>
            <InnerShadow />
            <div className="absolute top-0 left-0 card-bg-gradient !z-10 w-full h-full"></div>
            <img
              src={imgURL}
              alt="preview"
              className="!z-0 object-cover absolute top-0 left-0"
            />
            <CardIconWrapper onClick={[resetImg]}>
              <TrashIcon className="w-5/6 h-5/6" style={{ width: 20 }} />
            </CardIconWrapper>
          </>
        ) : (
          <label
            htmlFor="uploadImg"
            className="w-full h-full border border-dotted rounded-b-sm cursor-pointer flex-center border-base-color-1 rounded-t-md">
            <NewImgIcon className="!text-base-color-1" style={{ width: 20 }} />
          </label>
        )}
        <input
          type="file"
          id="uploadImg"
          className="absolute hidden"
          accept="image/png, image/gif, image/jpeg"
          onChange={(e) => selectImg(e)}
        />
      </div>
      <div className="z-20 px-2">
        <input
          defaultValue={cat.Label || ''}
          className={`w-full h-6 text-sm input-style ${
            err === 'label' ? 'input-error' : null
          }`}
          ref={labelRef}
          maxLength={25}
          placeholder="enter a category"
          onChange={() => setErr('')}
        />
      </div>
      {err && (
        <p className="!-mt-1 !ml-5 z-20 w-full h-6 error-msg-sm font-semibold">
          {err === 'label'
            ? 'Enter a category.'
            : err === 'img'
            ? 'Image not saved.'
            : 'Category not saved.'}
        </p>
      )}
      <div className="flex gap-2 mb-2">
        <button type="button" className="z-20 btn-base-var" onClick={onCancel}>
          back
        </button>
        <button
          type="button"
          className="z-20 btn-base"
          onClick={(e) => handleSubmit(e)}>
          save
        </button>
      </div>
    </div>
  );
};

export default EditCategory;
